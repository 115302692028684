<template>
    <div class="auth-wrapper auth-v2">
        <div class="auth-inner">
            <!-- brand logo -->
            <!-- <router-link to="/"
                   class="brand-logo d-flex align-center">
        <v-img :src="appLogo"
               max-height="30px"
               max-width="30px"
               alt="logo"
               contain
               class="me-3 "></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link> -->
            <!--/ brand logo -->

            <v-row class="auth-row ma-0">
                <v-col lg="7"
                       class="d-none d-lg-block position-relative overflow-hidden pa-0">
                    <div class="auth-illustrator-wrapper login-bg">
                        <div class="logo-size">
                            <img class="logo"
                                 :src="require(`@/assets/images/logo-designs/Reverse/Digital/myboat-logo-reverse-rgb.svg`)">
                        </div>

                    </div>
                </v-col>


                <v-col lg="5"
                       class="auth-bg pa-10 pb-0">
                    <v-row style="justify-content: end;">
                        <app-bar-i18n></app-bar-i18n>
                    </v-row>
                    <v-row class="pt-16">
                        <v-col cols="12"
                               sm="8"
                               md="6"
                               lg="12"
                               class="mx-auto">
                            <v-form ref="loginForm"
                                    class="multi-col-validation"
                                    lazy-validation
                                    @submit.prevent="submit"
                                    v-model="valid">
                                <v-card flat>
                                    <v-card-text class="d-flex align-start">

                                        <v-icon class="arabic-arrow"
                                                color="green-darken-2"
                                                style="font-size: 31px;cursor: pointer;"
                                                @click="back">
                                            mdi-arrow-left
                                        </v-icon>
                                        <div>
                                            <p class="text-2xl text-center text--primary mb-2">

                                                <span class="font-weight-light"> {{ $t('Reset') }}</span> <span
                                                      class="font-weight-bold">{{ $t('Password?') }}</span>
                                                <!-- 👋🏻 -->
                                            </p>
                                            <p style="font-size: 13px; font-weight:500"
                                               class="mb-2 mt-6 text-center">
                                                {{ $t('Please enter your New Password and Confirm Password') }}
                                                {{ $t('to reset your password') }}
                                            </p>
                                        </div>

                                    </v-card-text>
                                    <!-- login form -->
                                    <v-card-text>
                                        <p class="font-weight-semibold text-black mb-2">
                                            {{ $t('New Password') }}
                                        </p>
                                        <v-text-field :type="isPasswordVisible ? 'text' : 'password'"
                                                      :append-icon="password ? (isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline) : ''"
                                                      @click:append="isPasswordVisible = !isPasswordVisible"
                                                      @keyup.enter="resetPassword"
                                                      v-model="password"
                                                      :rules="passwordRules"
                                                      outlined
                                                      placeholder="Password"
                                                      prepend-inner-icon="mdi-key"
                                                      hide-details="auto"
                                                      class="mb-2">
                                            <template #message="{ message }">
                                                {{ $t(message) }}
                                            </template>
                                        </v-text-field>

                                        <p class="font-weight-semibold text-black mb-2">
                                            {{ $t('Confirm New Password') }}
                                        </p>
                                        <v-text-field :type="isConfirmPasswordVisible ? 'text' : 'password'"
                                                      :append-icon="confirmPassword ? (isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline) : ''"
                                                      @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                                                      @keyup.enter="resetPassword"
                                                      v-model="confirmPassword"
                                                      outlined
                                                      placeholder="Confirm Password"
                                                      :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                                                      prepend-inner-icon="mdi-key"
                                                      hide-details="auto"
                                                      class="mb-2">
                                            <template #message="{ message }">
                                                {{ $t(message) }}
                                            </template>
                                        </v-text-field>

                                        <v-btn block
                                               color="primary"
                                               @click="resetPassword"
                                               :disabled="!valid"
                                               type="submit"
                                               class="mt-6 text-capitalize">
                                            {{ $t('Reset Password') }}
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import router from '@/router';
import userServices from '@/services/user-services';
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue';
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
    components: {
        AppBarI18n
    },
    data: () => ({
        valid: false,
        password: '',
        loginForm: null,
        confirmPassword: '',
        isPasswordVisible: false,
        isConfirmPasswordVisible: false,
        passwordRules: [
            (value) => !!value || "Password is required.",
            (value) => (value && value.length >= 6) || "password_info",
        ],
        confirmPasswordRules: [
            (value) => !!value || "Confirm password is required",
        ],
        icons: {
            mdiEyeOutline,
            mdiEyeOffOutline,
        }
    }),
    computed: {
        passwordConfirmationRule()
        {
            return () => this.password === this.confirmPassword || "Confirm Password must match";
        }
    },
    methods: {
        back()
        {
            window.history.back();
        },
        resetPassword()
        {
            this.$refs.loginForm.validate();
            if (this.$refs.loginForm.validate())
            {
                let email = localStorage.getItem('email')
                let otp = localStorage.getItem('otp');
                // console.log(this.otp);
                let obj = {
                    "password": this.password,
                    "confirm_password": this.confirmPassword,
                    "otp": otp,
                    "username": email,
                }
                userServices.newPassword(obj).then(res =>
                {
                    // console.log(res)
                    if (res.statusCode == 200)
                    {
                        router.push('/login');
                    }
                    else
                    {

                    }
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

::v-deep .v-text-field input {
    padding: 8px 8px 8px;
}

.text-black {
    color: #000;
}

::v-deep .v-input .v-input__append-inner .v-input__icon .v-icon {
    transform-origin: 10px;
    color: #00B2A9 !important;
}

::v-deep .v-input__icon .v-icon {
    color: #00B2A9 !important;
}
</style>
